import React from 'react';
import { Link } from 'react-router-dom';
import { TinyColor } from '@ctrl/tinycolor';
import { Button, ConfigProvider, Space } from 'antd';
import "./Home.css";

import Header from "../header/header";
import ChatRectangleBox from '../shared/ChatRectangleBox';


const Home = () => (
  <div className='bg-[#f3f3f9] h-screen'>
    <Header title="header" />

    <div className='pt-[20px]'>
      <div className='flex flex-row justify-between px-[72px]'>
        <div className='flex flex-col mb-[48px] w-1/2'>
          <h1 className='text-[#191B24] font-semibold text-2xl mb-[8px] font-montserrat'>VeryChat Platform </h1>
          <h3 className='text-lg font-normal font-montserrat'>Unlock the full potential of your work with our advanced AI tools. From document analysis to email management, streamline your tasks and boost efficiency with just a few clicks.</h3>
        </div>
        <div className='mt-[20px]'>
          <Link to='/FQA'>
            <div className='px-[24px] py-[12px] rounded-xl bg-[#08BAF8]'>
              <h3 className='fonttext-xl font-semibold text-white'>
                Verychat FQA
              </h3>
            </div>
          </Link>
        </div>
      </div>

      <ul className='mx-[72px] grid grid-cols-3 gap-8'>
        <li >
          <ChatRectangleBox
            title="Document chat"
            imageSrc="/icons/document-chat.svg"
            bgColor="#114FE3"
            content="Upload documents to quickly analyze, improve, and gain insights securely."
            link="/datachat/document"
          />
        </li>
        <li className="block">
          <ChatRectangleBox
            title="Web chat"
            imageSrc="/icons/web-chat.svg"
            content="Input any URL to summarize and interact with webpage content effortlessly."
            bgColor="#191B24"
            link="/datachat/web"
          />
        </li>
        <li className="block">
          <ChatRectangleBox
            title="Database chat"
            imageSrc="/icons/data.svg"
            content="Query and manage databases using natural language for seamless data interaction."
            bgColor="#0038AF"
            link="/datachat/database"
          />
        </li>
        <li className="block">
          <ChatRectangleBox
            title="Confluence wiki chat"
            imageSrc="/icons/confluence-wiki-chat.svg"
            bgColor="#3B6AFD"
            content="Collaborate and enhance Wiki content with AI, making it more organized and user-friendly."
            link="/datachat/confluence"
          />
        </li>
        <li className="block">
          <ChatRectangleBox
            title="Sharepoint folder chat"
            subtitle="Coming soon"
            imageSrc="/icons/sharepoint.svg"
            bgColor="#0038AF"
            content="Use natural language commands to navigate and manage SharePoint folders efficiently."
            link="/"
          />
        </li>
        <li className="block">
          <ChatRectangleBox
            title="Mailbox chat"
            subtitle="Coming soon"
            imageSrc="/icons/mail-chat.svg"
            bgColor="#001551"
            content="Streamline email management with AI. Organize, summarize, and respond to emails quickly through a conversational interface."
            link="/"
          />
        </li>
      </ul>
    </div>

  </div>

);
export default Home;