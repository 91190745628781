import './ChatLayout.css';
import { useNavigate } from "react-router-dom";
const ChatLayout = (props) => {
    const navigate = useNavigate();
    const otherTools = [
        {
            title: "Document Chat",
            desc: "Upload your documents to analyze, improve, and get insights quickly and securely.",
            link: "/datachat/document"
        },
        {
            title: "Database Chat",
            desc: "Query and manage your databases using natural language for easy data interaction.",
            link: "/datachat/database"
        },
        {
            title: "Web Chat",
            desc: "Input any URL to access, summarize, and interact with web page content effortlessly. ",
            link: "/datachat/web"
        },
        {
            title: "Confluence Wiki Chat",
            desc: "Collaborate and enhance content in your Wiki with AI assistance, making it organized and user-friendly. ",
            link: "/datachat/confluence"
        },
        {
            title: "SharePoint Folder Chat (Coming Soon)",
            desc: "Navigate and manage your SharePoint folders using natural language commands for efficient file handling."
        },
        {
            title: "Mailbox Chat (Coming Soon) ",
            desc: "Manage your emails with AI. Organize, summarize, and respond to emails quickly through a conversational interface."
        },
    ].filter(x => x.title !== props.title);
    const goTo = (link) => {
        link && navigate(link);
    }
    return (
        <div className="chat_layout">
            <div className="breadcrumb">
                <img alt='' src="/icons/breadcrumb.svg"/>
                <div className='breadcrumb_item breadcrumb_parent'>
                    <a href="/">Dashboard</a>
                </div>
                <div className='breadcrumb_item'>/</div>
                <div className='breadcrumb_item breadcrumb_child'>{props.title}</div>
            </div>
            <div className='page_title'>{props.title}</div>
            {
                props.description && <div className='page_desc' dangerouslySetInnerHTML={{ __html: props.description }}/>
            }
            <div className='page_content'>
                {props.children}
            </div>
            <div className='other_tool'>
                <div className='other_tool_title'>Other AI Tools</div>
                <div className='other_tool_row'>
                    {
                        otherTools.slice(0, 3).map(tool => (
                            <>
                                <div className='other_tool_item' onClick={() => goTo(tool.link)}>
                                    <img width={32} src="/icons/sparkling.svg" alt="" />
                                    <div className='other_tool_item_info'>
                                        <div className="other_tool_item_title">{tool.title}</div>
                                        <div className="other_tool_item_text">{tool.desc}</div>
                                    </div>
                                </div>
                            </>
                        ))
                    }
                </div>
                <div className='other_tool_row other_tool_row_second'>
                    {
                        otherTools.slice(-(otherTools.length - 3)).map(tool => (
                            <>
                                <div className='other_tool_item' onClick={() => goTo(tool.link)}>
                                    <img width={32} src="/icons/sparkling.svg" alt="" />
                                    <div className='other_tool_item_info'>
                                        <div className="other_tool_item_title">{tool.title}</div>
                                        <div className="other_tool_item_text">{tool.desc}</div>
                                    </div>
                                </div>
                            </>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default ChatLayout;