import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header/header";
import "./Session.css";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import io from "socket.io-client";
import { DataChatServiceType } from "../contexts/Constant";
import ChatLayout from "../layout/ChatLayout";
import HowItWork from "../shared/HowItWork";

const { Dragger } = Upload;

function SessionPage() {
  let userId = Cookies.get("userId");
  const user = JSON.parse(localStorage.getItem("user"))
  if (!userId) {
    userId = uuidv4();
    Cookies.set("userId", userId, { expires: 365 });
    console.log("generate New User ID:", userId);
  }
  var USER_ID = userId;

  const uploadedFiles = useRef([]);
  const latestFile = useRef();
  const latestFileType = useRef();
  const latestSessionId = useRef();
  const sourceId = useRef();
  const [hideUpload, setHideUpload] = useState(false);

  const host = window.location.host;
  const protocol = window.location.protocol;
  var url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;

  const [isLoading, setIsLoading] = useState(false);

  // const history = useHistory();
  const navigate = useNavigate();
  const socket_tx = useRef();

  async function create_chat_session(chat_service_type, source_id, details) {
    try {
      console.log("create chat session: ", chat_service_type, source_id, details);
      const api_url = `${url}/api/v1/chat-session/create`;
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': user.token
        },
        body: JSON.stringify({
          chat_service_type: chat_service_type, 
          source_id: source_id, 
          details: details 
        })
      });
      
      const status = response.status;
      if (status === 200) {
        console.log('create chat session: success');
        const data = await response.json();
        const session_id = data.data.session_id;
        return session_id;
      }
      else if (status === 401) {
        message.error("Unauthorized access. Please login again.");
        navigate("/login");
      }
      else if(status == 402) {
        alert("License expired. Please contact support.");
      }
      else if(status == 403) {
        alert("Reach the maximum token limit in the license. Please contact support.");
      }
      else if(status == 404) {
        alert("Invalid License. Please contact support.");
      }
      else{
        alert(`create chat session: ${status}`);
      }
    } catch (error) {
      console.log('Could not check X-Frame-Options due to CORS restrictions');
    }
    return false;
  }

  const props = {
    name: "file",
    multiple: true,
    action: `${url}/upload`,
    headers: {
      'token': user.token,
    },
    onChange: async (info) => {
      const { status } = info.file;
      console.log("File status", status);
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        // check result from api response
        console.log("API response", info.file.response);
        const document_id = info.file.response.documentId;
        if (info.file.response.result === 1) {
          message.success("File uploaded successfully.");
        }
        else if (info.file.response.result === 2) {
          message.info("The file already exists.")
          // var path_name = `/datachat?docs=${info.file.response.fileName}`;
          // navigate(path_name);
        }
        // navigate("/datachat");
        latestFile.current = info.file.response.fileName;
        latestFileType.current = info.file.response.fileType;
        // sourceId.current = USER_ID + '_' + document_id;

        uploadedFiles.current.push({
          file_name: info.file.response.fileName,
          file_type: info.file.response.fileType,
          document_id: document_id
        });
        const documentIds = uploadedFiles.current.map(file => file.document_id);
        sourceId.current = USER_ID + '_' + documentIds.join('_');

        console.log("uploadedFiles", uploadedFiles.current, sourceId.current, documentIds);

        // setHideUpload(true);
      } else if (status === "error") {
        clear();
        message.error(`${info.file.name} file upload failed.`);
        const status_code = info.file.error.status;
        if (status_code === 401) {
          message.error("Unauthorized access. Please login again.");
          navigate("/login");
        }
        else if(status_code == 402) {
          alert("License expired. Please contact support.");
        }
        else if(status_code == 403) {
          alert("Reach the maximum token limit in the license. Please contact support.");
        }
        else if(status_code == 404) {
          alert("Invalid License. Please contact support.");
        }
      } else if (status === 'removed') {
        clear();
        uploadedFiles.current = uploadedFiles.current.filter(file => file.file_name !== info.file.response.fileName);
        const documentIds = uploadedFiles.current.map(file => file.document_id);
        sourceId.current = USER_ID + '_' + documentIds.join('_');
        console.log("uploadedFiles", uploadedFiles.current, sourceId.current);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    socket_tx.current = io.connect(url);

    return () => {
      if (socket_tx.current) {
        socket_tx.current.disconnect();
      }
    };
  }, []);

  const StreamText = () => {
    console.log("socketio: connected to ", url);

    socket_tx.current.on("connect", function () {
      let data = {
        sessionId: USER_ID,
      };
      socket_tx.current.emit("register", JSON.stringify(data));
    });

    socket_tx.current.on("message", function (markdownString) {
      console.log("message received: ", markdownString);
      setIsLoading(false);
      var path_name = `/datachat/session?type=${DataChatServiceType.DOCUMENT_CHAT}&docs=${latestFile.current}&id=${latestSessionId.current}`;
      navigate(path_name);
    });
  };
  useEffect(() => {
    StreamText();
  }, []);

  const onSubmit = async () => {
    if (latestFile.current !== undefined && latestFileType.current !== undefined && sourceId.current !== undefined) {
      const files = uploadedFiles.current;
      const details = {
        files: files,
      }
      const session_id = await create_chat_session(DataChatServiceType.DOCUMENT_CHAT, sourceId.current, details);
      if (!session_id) {
        return;
      }
  
      latestSessionId.current = session_id;
  
      let data = {
        sessionId: session_id,
      };
      socket_tx.current.emit("register", JSON.stringify(data));
  
      data = {
        sessionId: session_id,
        file_name: latestFile.current,
        fileType: latestFileType.current,
        datachat_type: DataChatServiceType.DOCUMENT_CHAT,
        details: details
      };
      socket_tx.current.emit("start_new_session", JSON.stringify(data));
  
      setIsLoading(true);
    } else {
      message.error("Please upload file to continue.");
    }
  }

  const clear = () => {
    latestFile.current = undefined;
    latestFileType.current = undefined;
    sourceId.current = undefined;
    latestSessionId.current = undefined;
    setHideUpload(false);
  }

  return (
    <>
      <Header title="header" />
      <ChatLayout
        title="Document Chat"
        description="Upload your documents to analyze, improve, and get insights quickly and securely."
      >
        {isLoading && (
          <div className="ss-modal">
            <span className="ss-loader"></span>Analyzing
          </div>
        )}
        <div className="chat_container">
          <div className="chat_container_main">
            <div className="chat_label">Describe this product</div>
            <Dragger {...props} rootClassName={`chat_dragger ${hideUpload ? 'chat_dragger_hide_upload' : ''}`}>
              <div className="chat_dragger_container">
                <img width={45} src="/icons/plus_circle.svg" alt="" />
                <div className="chat_dragger_text">Click or drag file to this area to upload </div>
                <div className="chat_dragger_text">Support PDF, Doc, PPT</div>
              </div>
            </Dragger>
            <div className="chat_label">Description (Optional)</div>
            <textarea name="description" className="chat_description" placeholder="Type or paste your text here"></textarea>
            <button onClick={onSubmit} className="btn_chat_generate">
              <img src="/icons/generate.svg" alt="" />
              <span>Generate</span>
            </button>
          </div>
          <HowItWork name='Document Chat'/>
        </div>
      </ChatLayout>
    </>
  );
}

export default SessionPage;
