import React, { useState, useRef, useEffect, useCallback } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const ExpandBox = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = React.useState('0px');
  const contentRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(prev => !prev);
  };

  const calculateHeight = useCallback(() => {
    if (contentRef.current) {
      setHeight(`${contentRef.current.scrollHeight + 64 + 86}px`);
    }
  }, [isExpanded]);

  useEffect(() => {
    calculateHeight();
  }, [isExpanded, calculateHeight]);

  return (
    <div
      className={`bg-white px-[20px] py-[32px] w-full rounded-lg shadow-md mb-4 transition-all duration-300 ease-in-out border ${isExpanded ? 'border-blue-100 border-opacity-100' : 'border-transparent border-opacity-0'} overflow-hidden`}
      style={{ height: isExpanded ? height : '86px' }} // Adjust default height as needed
    >
      <div className="flex justify-between items-center cursor-pointer" onClick={toggleExpand}>
        <h3 className="text-lg font-semibold transition-colors duration-300 text-gray-800">{question}</h3>
        {isExpanded ? (
          <div className='flex items-center justify-center w-[20px] h-[20px] rounded-[20px] bg-blue-200 '>
            <MinusOutlined className="text-xs font-black text-white transition-transform duration-300 transform rotate-180" />
          </div>
        ) : (
          <div className='flex items-center justify-center w-[20px] h-[20px] rounded-[20px] bg-blue-200 '>
            <PlusOutlined className="text-xs font-black text-white transition-transform duration-300" />
          </div>
        )}
      </div>

      <div
        ref={contentRef}
        className={`mt-[50px] ext-gray-600 transition-opacity duration-300 ease-in-out ${isExpanded ? 'opacity-100' : 'opacity-0'}`}
      >
        <p>{answer}</p>
      </div>
    </div>
  );
};

export default ExpandBox;
