import React from "react";
import { Spin } from "antd"; // Import Ant Design's spinner

const LoadingScreen = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <Spin size="large" /> {/* Ant Design spinner */}
      <p className="mt-4 text-lg font-semibold text-gray-600">Redirecting...</p>
    </div>
  );
};

export default LoadingScreen;