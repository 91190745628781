// src/components/chatbox/ChatBox.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const ChatRectangleBox = ({ title, subtitle, content, imageSrc, bgColor = '#3B6AFD', link }) => {
  return (
    <Link
      to={link}
      className={`box p-5 rounded-[32px] flex flex-col justify-between items-cente] max-w-[100%] min-h-48 h-full`}
      style={{ backgroundColor: bgColor }}>
      <div>
        <div className='flex flex-row justify-between'>
          <h3 className={`text-white text-2xl self-start`}>{title}</h3>
          <img src={'/icons/arrow.svg'} alt={title} className="w-4 h-4" />
        </div>
        {subtitle ? <h5 className={`text-white text-base self-start`}>{subtitle}</h5> : null}
        <h5 className="text-[#B6C4FF] mt-[16px] self-start">{content}</h5>
      </div>
      <img className="w-8 h-8 self-end" src={imageSrc} alt="" />
    </Link>
  );
};

export default ChatRectangleBox;
