import React from 'react';
import "./ChatBox.css";


const ChatBox = ({ title, content = "Hover to see details", imageSrc, bgColor = '#3B6AFD' }) => {
  return (
    <div class="card">
      <div class="card__content relative transition-transform duration-1000 max-w-[100%] h-full">
        <div class="card__front rounded-[20px] flex flex-col justify-between absolute top-0 bottom-0 right-0 left-0 p-5" style={{ backgroundColor: bgColor }}>
          <h3 className="text-white text-base mb-[16px] self-start">{title}</h3>
          <img className="w-8 h-8 self-end" src={imageSrc} alt="" />
        </div>

        <div class="card__back rounded-[20px] absolute top-0 bottom-0 right-0 left-0 p-5" style={{ backgroundColor: bgColor }}>
          <h3 className="text-white 'text-base'} mb-[16px] self-start">{content}</h3> 
        </div>
      

      </div>
    </div>

  );
};

export default ChatBox;