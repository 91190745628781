import React from "react";
import Header from "../header/header";
import ExpandBox from "../layout/ExpandBox";
import ChatLayout from "../layout/ChatLayout";

function FQAPage() {
  const data1 = [
    {
      question: "Is my data secure?",
      answer: "Absolutely! We use advanced security measures to protect your data and ensure your virtual agents operate within legal and ethical boundaries."
    },
    {
      question: "How quickly can I expect results?",
      answer: "Most tasks are completed within minutes, though the time may vary based on the complexity and size of the input."
    },
    {
      question: "Do I need any special skills to use these tools?",
      answer: "Not at all! Our tools are designed for ease of use and don’t require any technical expertise."
    }
  ]

  const data2 = [
    {
      question: "Can I have a custom AI solution?",
      answer: "Yes! We can create AI solutions like predictive models, automation systems, and recommendation engines to address your unique business needs. Contact us for more details on how we can tailor a solution just for you."
    },
    {
      question: "How do you ensure the AI solutions remain effective?",
      answer: "We continuously monitor and improve our AI systems. Regular updates and proactive troubleshooting ensure your AI solutions stay up-to-date and perform at their best."
    },
    {
      question: "Can I integrate these tools with my existing systems?",
      answer: "Yes, our AI tools are designed to easily integrate with your current software and workflows. We can help you set up seamless integrations to ensure everything works smoothly together."
    }
  ]

  return (
    <div>
      <Header title="header" />
      <ChatLayout title="FQA">
        <div className="flex flex-row mx-[72px] mr-[40px]">
          <div className="flex flex-col w-full">
            {data1.map((e) => (
              <ExpandBox
                question={e.question}
                answer={e.answer}
              />
            ))}
          </div>

          <div className="flex flex-col w-full ml-[40px]">
            {data2.map((e, index) => (
              <ExpandBox
                question={e.question}
                answer={e.answer}
              />
            ))}
          </div>
        </div>
        </ChatLayout>
    </div>
  );
}

export default FQAPage;
