import React, { useRef, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header/header";
import "./ConfluenceChatSession.css";

import { Select, Space } from 'antd';
import { AndroidOutlined, AppleOutlined, IeOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { Form, Input } from 'antd';
import { Upload, Button } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';

import { message } from "antd";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import io from "socket.io-client";
import { DataChatServiceType } from "../contexts/Constant";
import { AntDesignOutlined } from '@ant-design/icons';
import { ConfigProvider } from 'antd';
import UserContext from '../contexts/UserContext';
import ChatLayout from "../layout/ChatLayout";
import HowItWork from "../shared/HowItWork";

const { Dragger } = Upload;


function ConfluenceChatSession() {

  const { loggedIn, email, fullName, api_token } = useContext(UserContext);


  useEffect(() => {
    console.log("ConfluenceChatSession");
  }, []);

  let userId = Cookies.get("userId");
  const user = JSON.parse(localStorage.getItem("user"))
  if (!userId) {
    userId = uuidv4();
    Cookies.set("userId", userId, { expires: 365 });
    console.log("generate New User ID:", userId);
  }
  var USER_ID = userId;

  var latestFile = useRef();
  var latestSessionId = useRef();

  const host = window.location.host;
  const protocol = window.location.protocol;
  var url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;

  const [isLoading, setIsLoading] = useState(false);

  // const history = useHistory();
  const navigate = useNavigate();
  const socket_tx = useRef();

  useEffect(() => {
    socket_tx.current = io.connect(url);

    return () => {
      if (socket_tx.current) {
        socket_tx.current.disconnect();
      }
    };
  }, []);

  async function verify_confluence_info(details) {
    try {
      const api_url = `${url}/api/v1/chat-session/verify-confluence`;
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': user.token
        },
        body: JSON.stringify(details)
      });

      if (response.status === 200) {
        console.log('verify confluence info: success');
        return true;
      }
    } catch (error) {
      console.log('Could not check X-Frame-Options due to CORS restrictions');
    }
    alert("Invalid Confluence Infomaion, Please check again.");
    return false;
  }

  async function create_chat_session(chat_service_type, source_id, details) {
    try {
      const api_url = `${url}/api/v1/chat-session/create`;
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': user.token
        },
        body: JSON.stringify({
          chat_service_type: chat_service_type,
          source_id: source_id,
          details: details
        })
      });

      const status = response.status;
      if (status === 200) {
        console.log('create chat session: success');
        const data = await response.json();
        const session_id = data.data.session_id;
        return session_id;
      }
      else if (status === 401) {
        message.error("Unauthorized access. Please login again.");
        navigate("/login");
      }
      else if (status == 402) {
        alert("License expired. Please contact support.");
      }
      else if (status == 403) {
        alert("Reach the maximum token limit in the license. Please contact support.");
      }
      else if (status == 404) {
        alert("Invalid License. Please contact support.");
      }
      else {
        alert(`create chat session: ${status}`);
      }
    } catch (error) {
      console.log('Could not check X-Frame-Options due to CORS restrictions');
    }
    return false;
  }

  async function start_new_session(source_id, details) {
    try {
      const result = await verify_confluence_info(details);
      if (!result) {
        return;
      }

      const session_id = await create_chat_session(DataChatServiceType.CONFLUENCE_CHAT, source_id, details);
      if (!session_id) {
        return;
      }

      latestSessionId = session_id;

      let data = {
        sessionId: latestSessionId,
      };
      socket_tx.current.emit("register", JSON.stringify(data));

      data = {
        sessionId: latestSessionId,
        file_name: "",
        fileType: "",
        datachat_type: DataChatServiceType.CONFLUENCE_CHAT,
        details: details
      };
      socket_tx.current.emit("start_new_session", JSON.stringify(data));

      setIsLoading(true);
    } catch (error) {
      console.log('Could not start new session');
    }
    return false;
  }

  async function handle_inputform(values) {
    const details = {
      confluence_base_url: values.url,
      username: values.username,
      api_token: values.tokenkey,
    }
    const url = `${details.url}/${details.tokenkey}`;
    const source_id = USER_ID + '_' + url.hashUrlToUuid();
    await start_new_session(source_id, details);
  }

  const StreamText = () => {
    console.log("socketio: connected to ", url);

    socket_tx.current.on("connect", function () {
      let data = {
        sessionId: USER_ID,
      };
      socket_tx.current.emit("register", JSON.stringify(data));
    });

    socket_tx.current.on("message", function (markdownString) {
      console.log("message received: ", markdownString);
      setIsLoading(false);
      var path_name = `/datachat/session?type=${DataChatServiceType.CONFLUENCE_CHAT}&docs=a&id=${latestSessionId}`;
      navigate(path_name);
    });
  };
  useEffect(() => {
    StreamText();
  }, []);

  return (
    <>
      <Header title="header" />
      <ChatLayout
        title="Confluence Wiki Chat "
        description="Collaborate and enhance Wiki content with AI, making it more organized and user-friendly."
      >
        <div className="chat_container">
          <div className="chat_container_main">
              <div className="wiki_chat_container">
                <Form labelAlign="left" 
                  initialValues={{ username: email }}
                  onFinish={async (values) => handle_inputform(values)}>
                    <div className="wiki_chat_label">URL</div>
                    <Form.Item
                      name="url"
                      rules={[{ required: true, message: 'Please input your url!' }]}
                    >
                      <Input id='url' className="wiki_chat_input" placeholder="Input your URL" autoComplete="off" />
                    </Form.Item>
                    <div className="wiki_chat_label">Username</div>
                    <Form.Item
                      name="username"
                      rules={[{ required: true, message: 'Please input your username or email!' }]}
                    >
                      <Input id='username' className="wiki_chat_input" placeholder="Input your username" autoComplete="off" />
                    </Form.Item>
                    <div className="wiki_chat_label">Token Key</div>
                    <Form.Item
                      name="tokenkey"
                      rules={[{ required: true, message: 'Please input your token!' }]}
                    >
                      <Input.Password id='tokenkey' className="wiki_chat_input" placeholder="Input your token key" autoComplete="off" />
                    </Form.Item>
                    <ConfigProvider
                      button={{
                        className: "cf-connect",
                      }}
                    >
                  <Space style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type="primary" size="large" icon={<AntDesignOutlined />} htmlType="submit">
                      Connect
                    </Button>
                  </Space>
                  </ConfigProvider>
                </Form>
              </div>
          </div>
          <HowItWork name='Confluence Wiki Chat'/>
        </div>
      </ChatLayout>
    </>
  );
}

export default ConfluenceChatSession;