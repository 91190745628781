import React, { useRef, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header/header";
import "./DatabaseChatSession.css";
import { Button, Col, Flex, Form, Input, Row, Select, Space, Tabs, Upload } from 'antd';
import { message } from "antd";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import io from "socket.io-client";
import { DataChatServiceType, DatabaseType } from "../contexts/Constant";
import { ConfigProvider } from 'antd';
import ChatLayout from "../layout/ChatLayout";
import HowItWork from "../shared/HowItWork";
import { AndroidOutlined, AppleOutlined, DatabaseOutlined, UploadOutlined, AntDesignOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

function DatabaseChatSession() {

  useEffect(() => {
    console.log("DatabaseChatSession");
  }, []);

  let userId = Cookies.get("userId");
  const user = JSON.parse(localStorage.getItem("user"))
  if (!userId) {
    userId = uuidv4();
    Cookies.set("userId", userId, { expires: 365 });
    console.log("generate New User ID:", userId);
  }
  var USER_ID = userId;

  const latestFile = useRef();
  const latestSessionId = useRef();
  const sourceId = useRef();
  const [hideUpload, setHideUpload] = useState(false);

  const host = window.location.host;
  const protocol = window.location.protocol;
  var url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;

  const [isLoading, setIsLoading] = useState(false);

  // const history = useHistory();
  const navigate = useNavigate();
  const socket_tx = useRef();

  useEffect(() => {
    socket_tx.current = io.connect(url);

    return () => {
      if (socket_tx.current) {
        socket_tx.current.disconnect();
      }
    };
  }, []);

  async function create_chat_session(chat_service_type, source_id, details) {
    try {
      const api_url = `${url}/api/v1/chat-session/create`;
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': user.token
        },
        body: JSON.stringify({
          chat_service_type: chat_service_type,
          source_id: source_id,
          details: details
        })
      });

      const status = response.status;
      if (status === 200) {
        console.log('create chat session: success');
        const data = await response.json();
        const session_id = data.data.session_id;
        return session_id;
      }
      else if (status === 401) {
        message.error("Unauthorized access. Please login again.");
        navigate("/login");
      }
      else if (status == 402) {
        alert("License expired. Please contact support.");
      }
      else if (status == 403) {
        alert("Reach the maximum token limit in the license. Please contact support.");
      }
      else if (status == 404) {
        alert("Invalid License. Please contact support.");
      }
      else {
        alert(`create chat session: ${status}`);
      }
    } catch (error) {
      console.log('Could not check X-Frame-Options due to CORS restrictions');
    }
    return false;
  }

  async function start_new_session(source_id, details) {
    try {
      setIsLoading(true);
      const session_id = await create_chat_session(DataChatServiceType.DATABASE_CHAT, source_id, details);
      if (!session_id) {
        return;
      }

      latestSessionId.current = session_id;

      let data = {
        sessionId: session_id,
      };
      socket_tx.current.emit("register", JSON.stringify(data));

      data = {
        sessionId: session_id,
        file_name: "",
        fileType: "",
        datachat_type: DataChatServiceType.DATABASE_CHAT,
        details: details
      };
      socket_tx.current.emit("start_new_session", JSON.stringify(data));
    } catch (error) {
      setIsLoading(false);
      console.log('Could not start new session');
    }
    return false;
  }

  async function handle_db_inputform(values) {
    const details = {
      input_type: values.dbType,
      path: "",
      username: values.username,
      password: values.password,
      host: values.host,
      port: values.port,
      db_name: values.dbName
    }
    const url = `${details.input_type}:${details.host}:${details.port}/${details.db_name}`;
    const source_id = USER_ID + '_' + url.hashUrlToUuid();
    await start_new_session(source_id, details);
  }

  const props = {
    name: "file",
    multiple: true,
    action: `${url}/upload`,
    headers: {
      'token': user.token,
    },
    onChange: async (info) => {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        // check result from api response
        console.log("API response", info.file.response);
        const document_id = info.file.response.documentId;
        if (info.file.response.result === 1) {
          message.success("File uploaded successfully.");
        }
        else if (info.file.response.result === 2) {
          message.info("The file already exists.")
          // var path_name = `/datachat?docs=${info.file.response.fileName}`;
          // navigate(path_name);
        }
        // navigate("/datachat");
        latestFile.current = info.file.response.fileName;
        sourceId.current = USER_ID + '_' + document_id;
        setHideUpload(true);
        // var path_name = `/datachat?docs=${latestFile}`;
        // navigate(path_name);
      } else if (status === "error") {
        clear();
        message.error(`${info.file.name} file upload failed.`);
        const status_code = info.file.error.status;
        if (status_code === 401) {
          message.error("Unauthorized access. Please login again.");
          navigate("/login");
        }
        else if (status_code == 402) {
          alert("License expired. Please contact support.");
        }
        else if (status_code == 403) {
          alert("Reach the maximum token limit in the license. Please contact support.");
        }
        else if (status_code == 404) {
          alert("Invalid License. Please contact support.");
        }
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const StreamText = () => {
    console.log("socketio: connected to ", url);

    socket_tx.current.on("connect", function () {
      let data = {
        sessionId: USER_ID,
      };
      socket_tx.current.emit("register", JSON.stringify(data));
    });

    socket_tx.current.on("message", function (markdownString) {
      console.log("message received: ", markdownString);
      setIsLoading(false);
      var path_name = `/datachat/session?type=${DataChatServiceType.DATABASE_CHAT}&docs=self_RAG.pdf&id=${latestSessionId}`;
      navigate(path_name);
    });
  };
  useEffect(() => {
    StreamText();
  }, []);

  const { getPrefixCls } = useContext(ConfigProvider.ConfigContext);

  const onSubmit = async () => {
    if (latestFile.current && sourceId.current) {
      const details = {
        input_type: DatabaseType.SQL_LITE,
        path: latestFile.current,
        username: "",
        password: "",
        host: "",
        port: ""
      };
      await start_new_session(sourceId.current, details);
    } else {
      message.error("Please upload file to continue.");
    }
  }

  const clear = () => {
    latestFile.current = undefined;
    sourceId.current = undefined;
    setHideUpload(false);
  }

  return (
    <>
      <Header title="header" />
      <ChatLayout
        title="Database Chat"
        description="Query and manage your databases using natural language for easy data interaction. Our AI-powered Database Chat tool simplifies database management and enhances productivity."
      >
        {isLoading && (
          <div className="ss-modal">
            <span className="ss-loader"></span>Analyzing
          </div>
        )}
        <div className="chat_container">
          <div className="chat_container_main">
            <Tabs
              defaultActiveKey="1"
              centered={true}
              size="large"
              items={[AppleOutlined, AndroidOutlined].map((Icon, i) => {
                const id = String(i + 1);
                return {
                  key: id,
                  label: i === 0 ? "File" : `Database`,
                  icon: i === 0 ? <UploadOutlined /> : <DatabaseOutlined />,
                  children: i === 0 ? (
                    <>
                      <div className="chat_label">Describe this product</div>
                      <Dragger
                        {...props}
                        beforeUpload={(file, fileList) => {
                          if (fileList.length > 1) {
                            message.error('You can only upload one file!');
                            return false;
                          }
                          // Check file format
                          const isSqlite = file.name.endsWith('.sqlite');
                          if (!isSqlite) {
                            message.error('You can only upload SQLite files!');
                          }
                          return isSqlite || Upload.LIST_IGNORE;
                        }}
                        rootClassName={`chat_dragger ${hideUpload ? 'chat_dragger_hide_upload' : ''}`
                      }>
                        <div className="chat_dragger_container">
                          <img width={45} src="/icons/plus_circle.svg" alt="" />
                          <div className="chat_dragger_text">Click or drag file to this area to upload </div>
                          <div className="chat_dragger_text">Support SQL Lite</div>
                        </div>
                      </Dragger>
                      <div className="chat_label">Description (Optional)</div>
                      <textarea name="description" className="chat_description" placeholder="Type or paste your text here"></textarea>
                      <button onClick={onSubmit} className="btn_chat_generate">
                        <img src="/icons/generate.svg" alt="" />
                        <span>Generate</span>
                      </button>
                    </>
                  ) : (
                    <>
                      <Form className="form_db_chat" labelAlign="left" onFinish={async (values) => handle_db_inputform(values)}>
                        <Row>
                          <Col xs={24} xl={12} className="ant-col-form">
                            <Flex vertical>
                              <div className="db_chat_label">Type</div>
                              <Form.Item
                                name="dbType"
                                rules={[{ required: true, message: 'Please select your DB type!' }]}
                              >
                                <Select placeholder='Select database type'>
                                  <Select.Option value={DatabaseType.MYSQL}>MySQL</Select.Option>
                                  <Select.Option value={DatabaseType.POSTGRESQL}>PostgreSQL</Select.Option>
                                </Select>
                              </Form.Item>
                              <div className="db_chat_label">DB Name</div>
                              <Form.Item
                                name="dbName"
                                rules={[{ required: true, message: 'Please input your database name!' }]}
                              >
                                <Input placeholder="Enter database name" className="db_chat_input"/>
                              </Form.Item>
                              <div className="db_chat_label">Host</div>
                              <Form.Item
                                name="host"
                                rules={[{ required: true, message: 'Please input your host!' }]}
                              >
                                <Input placeholder="Enter database host" className="db_chat_input"/>
                              </Form.Item>
                            </Flex>
                          </Col>
                          <Col xs={24} xl={12} className="ant-col-form">
                            <div className="db_chat_label">Port</div>
                            <Form.Item
                              name="port"
                              rules={[
                                { required: true, message: 'Please input your port!' },
                                { pattern: /^[0-9]+$/, message: 'Port must be a number!' }
                              ]}
                            >
                              <Input placeholder="Enter database port" className="db_chat_input"/>
                            </Form.Item>
                            <div className="db_chat_label">Username</div>
                            <Form.Item
                              name="username"
                              rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                              <Input placeholder="Enter username" className="db_chat_input"/>
                            </Form.Item>
                            <div className="db_chat_label">Password</div>
                            <Form.Item
                              name="password"
                              rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                              <Input.Password placeholder="Enter password" className="db_chat_input"/>
                            </Form.Item>
                          </Col>
                        </Row>
                        <ConfigProvider
                          button={{
                            className: "db-connect",
                          }}
                        >
                          <Space style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button type="primary" size="large" icon={<AntDesignOutlined />} htmlType="submit">
                              Connect
                            </Button>
                          </Space>
                        </ConfigProvider>
                      </Form>
                    </>
                  )
                }
              })}
            />
          </div>
          <HowItWork name='Database Chat'/>
        </div>
      </ChatLayout>
    </>
  );
}

export default DatabaseChatSession;