import "./HowItWork.css";

const HowItWork = (props) => {
    const guideData = [
        {
            name: 'Document Chat',
            steps: [
                {
                    title: 'Upload Your Document:',
                    desc: 'Simply upload your document in any common format.'
                },
                {
                    title: 'Analysis:',
                    desc: 'Our advanced AI analyzes the content for errors, improvements, and key insights.'
                },
                {
                    title: 'Get Results:',
                    desc: 'Receive a detailed analysis with suggestions and improvements within minutes.'
                }
            ]
        },
        {
            name: 'Web Chat',
            steps: [
                {
                    title: 'Enter a URL:',
                    desc: 'Simply input the URL of the web page you want to analyze.'
                },
                {
                    title: 'AI Processing:',
                    desc: 'Our AI quickly processes the content, extracting key information and summarizing it for you.'
                },
                {
                    title: 'Get Results:',
                    desc: 'Receive a concise summary and insights almost instantly.'
                }
            ]
        },
        {
            name: 'Database Chat',
            steps: [
                {
                    title: 'Connect Your Database',
                    desc: 'Easily connect to your existing database.'
                },
                {
                    title: 'Ask Questions:',
                    desc: 'Use natural language to query your database and get the information you need.'
                },
                {
                    title: 'Get Results:',
                    desc: ' Get Results: Receive accurate, real-time responses and insights.'
                }
            ]
        },
        {
            name: 'Confluence Wiki Chat',
            steps: [
                {
                    title: 'Enter your details:',
                    desc: 'Provide the URL, username, and token key for the Confluence page you want to analyze.'
                },
                {
                    title: 'Connect:',
                    desc: 'Use natural language commands to interact with your Confluence data and get insights instantly.'
                },
                {
                    title: 'Get Results:',
                    desc: 'Receive accurate, real-time responses and actionable insights. '
                }
            ]
        }
    ].find(x => props.name === x.name);
    return (
        <div className="how_it_work_container">
            <div className="how_it_work_title">How It Works</div>
            {
                guideData.steps.map((item, i) => (
                    <div className="how_it_work_block">
                        <div className="how_it_work_no">{i + 1}.</div>
                        <div className="how_it_work_block_info">
                            <span className="how_it_work_block_title">{item.title}</span>
                            <span className="how_it_work_block_text">{item.desc}</span>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default HowItWork;