import React from "react"
import { useNavigate } from "react-router-dom";
import Header from "../header/header";
import ChatBox from "../shared/ChatBox";

import { Button, Checkbox, Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
const onFinish = (values) => {
    console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const Login = (props) => {
    const { loggedIn, email } = props
    const navigate = useNavigate();
    const host = window.location.host;
    const protocol = window.location.protocol;
    const main_url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;
    console.log("main_url", main_url)

    const signInWithMicrosoft = async () => {
        if (loggedIn) {
            localStorage.removeItem("user")
            props.setLoggedIn(false)
        } else {
            const response = await fetch(`${main_url}/api/v1/auth/external-login-url`);
            console.log(response)
            const data = await response.json();
            const url = data.data.url;
            // Redirect to the URL
            window.location.href = url;
        }
    }

    if (loggedIn) {
        console.log("navigate to /")
        navigate('/')
    }

    return <div>
        <div className="absolute">
            <Header title="header" />
        </div>

        <div className="flex flex-col items-center bg-[#f3f3f9] h-lvh font-montserrat">
            <div className="flex flex-row min-h-screen items-center     ">

                <div className="w-1/2 p-10 flex flex-row items-center justify-center">
                    <Form
                        className="w-80 p-8"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <h2 className="text-4xl font-bold text-center mb-6 text-[#0066FF]">Sign In</h2>

                        {/* Email/Phone Input */}
                        <Form.Item
                            name="email"
                            className="mb-4 min-w"
                            rules={[
                                {
                                required: true,
                                message: 'Please input your username!',
                                },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <div className="text-gray-700 mb-2">Email/Phone number</div>
                            <Input
                                placeholder="Enter email/phone number"
                                className="p-2 rounded border border-gray-300 w-full"
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            className="mb-4"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <div className="text-gray-700 mb-2">Password</div>
                            <Input.Password
                                placeholder="Enter password"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please input your password!',
                                    },
                                ]}
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                className="p-2 rounded border border-gray-300 w-full"
                            />
                        </Form.Item>

                        <Form.Item
                            className="flex justify-center"
                            name="remember"
                            valuePropName="checked"
                        >
                            <div className="flex items-center ">
                                <Checkbox className="mr-2 " />
                                <span>Remember me</span>
                            </div>
                        </Form.Item>

                        <Form.Item className="flex flex-column justify-center">
                            <Button type="primary" htmlType="submit" className="w-full flex items-center justify-center bg-[#0066FF] text-white py-5 mb-4">
                                Sign
                            </Button>
                            <Button type="default" onClick={signInWithMicrosoft} className="w-full flex items-center justify-center border border-[#0066FF] px-10 text-[#0066FF] py-5">
                                Sign In with Microsoft
                            </Button>
                        </Form.Item>
                    </Form>
                </div>


                <div className="w-1/2 p-10">
                    <div className="grid grid-cols-3 gap-4">
                        <ChatBox title="Document chat" imageSrc="/icons/document-chat.svg" bgColor="#114FE3" content="Quickly analyze documents." />
                        <ChatBox title="Web chat" imageSrc="/icons/web-chat.svg" bgColor="#191B24" content="Interact with any webpage content." />
                        <div className="box bg-cover bg-center rounded-[32px]" style={{ backgroundImage: `url('image1-url')` }}>
                            <img src='/image1.png' />
                        </div>

                        <div className="box bg-cover bg-center rounded-[32px]" style={{ backgroundImage: `url('image2-url')` }}>
                            <img src='/image2.png' />
                        </div>
                        <ChatBox title="Database chat" imageSrc="/icons/data.svg" bgColor="#0038AF" content="Query databases with natural language." />
                        <ChatBox title="Confluence wiki chat" imageSrc="/icons/confluence-wiki-chat.svg" bgColor="#3B6AFD" content="AI-enhanced Wiki knowledge management." />


                        <ChatBox title="Sharepoint folder" imageSrc="/icons/sharepoint.svg" bgColor="#0038AF" content="Manage SharePoint folders with ease."/>
                        <div className="box bg-cover bg-center rounded-[32px] col-span-2" style={{ backgroundImage: `url('image3-url')` }}>
                            <img src='/image3.png' />
                        </div>


                        <div className="box bg-cover bg-center rounded-[32px]" style={{ backgroundImage: `url('image4-url')` }}>
                            <img src='/image4.png' />
                        </div>
                        <ChatBox title="Mailbox chat" imageSrc="/icons/mail-chat.svg" bgColor="#001551" content="Organize and respond to emails using AI." />

                        <div className="box bg-cover bg-center rounded-[32px]" style={{ backgroundImage: `url('image5-url')` }}>
                            <img src='/image5.png' />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

export default Login